<template>
  <tr
    class="ReportTemplatesTableRow"
    @click="downloadTemplate"
  >
    <!-- First cell: reportTemplate.fileName -->
    <td class="ReportTemplatesTableRow__link text-truncate">
      <div
        class="ReportTemplatesTableRow__cell text-truncate pl-2"
        style="max-width: 640px"
      >
        <span
          class="text-truncate"
          v-text="reportTemplate.fileName"
        />
        <span
          v-if="downloading"
          class="ml-2 text--secondary text-truncate"
          v-text="$t('report.downloading')"
        />
      </div>
    </td>

    <!-- Second cell: reportTemplate date -->
    <td class="ReportTemplatesTableRow__link">
      <div
        class="ReportTemplatesTableRow__cell"
        v-text="fmtDate(reportTemplate.created)"
      />
    </td>

    <!-- Third cell: reportTemplate author -->
    <td class="ReportTemplatesTableRow__link">
      <div class="ReportTemplatesTableRow__cell justify-center">
        <v-tooltip
          v-if="reportTemplate.author != null"
          bottom
          color="#3C3A52"
          content-class="tooltip--arrow-top"
        >
          <template #activator="{ on, attrs }">
            <UserAvatar
              class="ReportTemplatesTableRow__cell"
              replace
              :user="reportTemplate.author"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span v-text="authorDisplayName" />
        </v-tooltip>
      </div>
    </td>

    <!-- Last cell: actions -->
    <td @click.stop>
      <div class="ReportTemplatesTableRow__cell">
        <v-btn
          v-if="!reportTemplate.isPredefined"
          icon
          :disabled="downloading"
          :loading="deleting"
          @click="deleteTemplate"
        >
          <v-icon v-text="'mdi-trash-can-outline'" />
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import { fmtDate } from '../helpers'

import UserAvatar from './UserAvatar'

export default {
  name: 'ReportTemplatesTableRow',

  components: {
    UserAvatar,
  },

  props: {
    reportTemplate: { type: Object, default: null },
  },

  data() {
    return {
      downloading: false,
      deleting: false,
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },

    authorDisplayName() {
      // author absence should be impossible, but unfortunately it is not
      if (!this.reportTemplate.author) return null

      const { firstName, lastName, userLogin } = this.reportTemplate.author
      return [firstName, lastName]
        .map(s => s?.trim?.())
        .filter(Boolean)
        .join(' ') || userLogin
    },
  },

  methods: {
    fmtDate,

    async downloadTemplate() {
      const { $store, reportTemplate: template } = this

      if (this.deleting || this.downloading) return

      this.downloading = true
      try {
        await $store.dispatch('report/downloadTemplate', { template })
      } catch (e) {
        console.error(e)
      } finally {
        this.downloading = false
      }
    },

    async deleteTemplate() {
      const { $store, reportTemplate: { uuid: templateId, fileName } } = this

      if (this.deleting || this.downloading) return

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('report.DeleteTemplateQ'),
        subtitle: this.$t('report.ReportTemplateName', { fileName }),
        consentLabel: this.$t('report.Delete'),
        // consentProps: { color: 'error', depressed: true },
      })

      if (!agreed) return

      this.deleting = true
      try {
        await $store.dispatch('report/deleteTemplate', { templateId })
      } catch (e) {
        console.error(e)
      } finally {
        this.deleting = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.ReportTemplatesTableRow
  td
    border-bottom: none !important

  a
    color: inherit
    text-decoration: none

  &__link
    cursor: pointer

  &__cell
    display: flex
    align-items: center
</style>
