<template>
  <AppBarLayout
    class="ReportTemplatesAppBar"
    v-bind="$attrs"
  >
    <template #header>
      {{ $t('report.ReportTemplates') }}
    </template>

    <template #actions>
      <v-btn
        color="primary"
        outlined
        replace
        :loading="uploading"
        @click="$refs.fileInput.click()"
      >
        {{ $t('report.UploadTemplate') }}
      </v-btn>

      <input
        ref="fileInput"
        class="ReportTemplatesAppBar__file-hidden-input"
        name="files"
        type="file"
        multiple
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pptx"
        @change.passive="onFilesSelected"
      >
    </template>
  </AppBarLayout>
</template>

<script>
import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'ReportTemplatesAppBar',

  components: {
    AppBarLayout,
  },

  inheritAttrs: false,

  data() {
    return {
      uploading: false,
    }
  },

  methods: {
    async onFilesSelected(event) {
      const promises = Array.from(event.target.files || [])
        .map(file => this.uploadTemplate(file)
          .then(() => ({ file, success: true, error: null }))
          .catch((e) => ({ file, success: false, error: e })))

      if (!promises.length) return

      this.uploading = true
      try {
        const uploadResults = await Promise.all(promises)
        if (!uploadResults.every(res => res.success)) {
          console.error(
            'Some of the files were uploaded with an error',
            { uploadResults },
          )
        }
      } finally {
        this.uploading = false
        await this.$nextTick()

        // eslint-disable-next-line
        this.$refs?.fileInput ? this.$refs.fileInput.value = null : null
      }
    },

    async uploadTemplate(file) {
      return this.$store.dispatch('report/uploadTemplate', { file })
    },
  },
}
</script>

<style lang="sass" scoped>
.ReportTemplatesAppBar
  &__file-hidden-input
    position: absolute
    opacity: 0
    pointer-events: none
</style>
