<template>
  <div class="ReportTemplates">
    <ReportTemplatesAppBar />

    <ReportTemplatesTable
      class="ReportTemplates__table"
      :report-templates="reportTemplates"
    />
  </div>
</template>

<script>
import ReportTemplatesAppBar from '../components/ReportTemplatesAppBar'
import ReportTemplatesTable from '../components/ReportTemplatesTable'

export default {
  name: 'ReportTemplates',

  components: {
    ReportTemplatesAppBar,
    ReportTemplatesTable,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('report.ReportTemplates')),
    }
  },

  computed: {
    reportTemplates() { return this.$store.getters['report/templates'] },
  },

  created() {
    this.$store.dispatch('report/getTemplates')
  },
}
</script>

<style lang="sass" scoped>
.ReportTemplates
  $y-padding: 64px
  max-width: 1152px + $y-padding * 2
  margin: 0 auto
  padding: 88px $y-padding

  &__title
    margin-bottom: 34px
</style>
