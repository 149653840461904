<template>
  <v-data-table
    class="ReportTemplatesTable"
    :headers="headers"
    :items="reportTemplates || []"
    :items-per-page="-1"
    :loading="reportTemplates == null"
    item-key="id"
    fixed-header
    hide-default-footer
    :no-data-text="$t('report.NoDataAvailable')"
    must-sort
    :sort-by.sync="sortModel"
  >
    <template #item="{ item: reportTemplate }">
      <ReportTemplatesTableRow :report-template="reportTemplate" />
    </template>
  </v-data-table>
</template>

<script>
import ReportTemplatesTableRow from './ReportTemplatesTableRow'

export default {
  name: 'ReportTemplatesTable',

  components: {
    ReportTemplatesTableRow,
  },

  props: {
    reportTemplates: { type: Array, default: null },
  },

  data: () => ({
    sortModel: 'fileName',
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('report.Template'),
          value: 'fileName',
          width: '100%',
        },
        { text: this.$t('report.Uploaded'), value: 'created' },
        { text: this.$t('report.Author'), value: 'author.firstName', align: 'center' },
        // Actions column
        {
          value: '_actions',
          sortable: false,
          align: 'end',
        },
      ]
    },
  },
}
</script>

<style lang="sass" scoped>
.ReportTemplatesTable
  border-radius: 8px
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2)

  ::v-deep
    .v-data-table__wrapper
      border-radius: 8px
    tr > th
      white-space: nowrap

      &:first-child
        padding-left: 24px

    tr > th, tr > td
      white-space: nowrap
      &:last-child
        padding: 0 16px 0 0
</style>
